import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es'; // Español
import 'moment/locale/pt-br'; // Portugués
import 'moment/locale/en-gb'; // Inglés británico

class VoidContext implements LenguageContext {
  get language(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setLanguage(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface LenguageContext {
  language: {
    language: 'en' | 'es' | 'pt';
    id: number;
  };
  setLanguage: Function;
}

export const LenguageContext = createContext<LenguageContext>(
  new VoidContext()
);

export const LenguageContextProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState<{
    language: 'en' | 'es' | 'pt';
    id: number;
  }>({
    language: 'es',
    id: 1,
  });

  useEffect(() => {
    moment.locale(language.language); // Aplica el idioma seleccionado a moment.js
  }, [language]);
  //moment.locale('es')

  return (
    <LenguageContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </LenguageContext.Provider>
  );
};
