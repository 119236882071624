import React, { createContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { objectFilter } from '../utils/objectFilter';

class VoidContext implements FiltersContext {
  get filters(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get changeFilters(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

export type Filters = {
  crops?: number[];
  farms?: string[];
  location?: string[];
  genotype?: number[];
  fiscal_name?: string[];
};

interface FiltersContext {
  filters: Filters;
  changeFilters: (filters: Filters) => void;
}

const parseFilters = (queryString: string) => {
  const { campo, cultivo, localidad, genotipo, razonSocial } = qs.parse(
    decodeURIComponent(queryString.slice(1)),
    {
      comma: true,
    }
  );

  return {
    crops: Array.isArray(cultivo)
      ? (cultivo as string[])?.map(Number)
      : cultivo
      ? [Number(cultivo)]
      : [],
    farms: Array.isArray(campo)
      ? (campo as string[])
      : campo
      ? [campo as string]
      : [],
    location: Array.isArray(localidad)
      ? (localidad as string[])
      : localidad
      ? [localidad as string]
      : [],
    genotype: Array.isArray(genotipo)
      ? (genotipo as string[])?.map(Number)
      : genotipo
      ? [Number(genotipo)]
      : [],
    fiscal_name: Array.isArray(razonSocial)
      ? (razonSocial as string[])
      : razonSocial
      ? [razonSocial as string]
      : [],
  };
};

const constructQueryString = (newFilters: Filters, queryString: string) => {
  const params = qs.parse(decodeURIComponent(queryString.slice(1)), {
    comma: true,
  });

  return qs.stringify(
    objectFilter(
      {
        ...params,
        campo: newFilters.farms,
        cultivo: newFilters.crops,
        localidad: newFilters.location,
        genotipo: newFilters.genotype,
        razonSocial: newFilters.fiscal_name,
      },
      (x) => !!x?.length
    ),
    { arrayFormat: 'comma' }
  );
};

const useFilters = (): FiltersContext => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [filters, setFilters] = useState<Filters>(parseFilters(search));

  useEffect(() => {
    const newFilters = parseFilters(search);

    setFilters(newFilters);
  }, [search, setFilters]);

  const changeFilters = (newFilters: Filters) => {
    const queryString = constructQueryString(newFilters, search);

    history.push({
      pathname: pathname,
      search: `?${queryString}`,
    });
  };

  return {
    filters,
    changeFilters,
  };
};

export const FiltersContext = createContext<FiltersContext>(new VoidContext());

export const FiltersContextProvider: React.FC = ({ children }) => {
  const filtersContext = useFilters();

  return (
    <FiltersContext.Provider value={filtersContext}>
      {children}
    </FiltersContext.Provider>
  );
};
